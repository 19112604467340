@use "sass:list";
@use "sass:math";

// Nebula Colours //
// Purple:
$color-purple-90: #321c64;
$color-purple-80: #45278d;
$color-purple-70: #592acb;
$color-purple-60: #7542e5;
$color-purple-50: #9059ff;
$color-purple-40: #ab71ff;
$color-purple-30: #c689ff;
$color-purple-20: #cb9eff;
$color-purple-10: #d9bfff;
$color-purple-05: #e7dfff;

// Green:
$color-green-90: #00736c;
$color-green-80: #00a49a;
$color-green-70: #1cc4a0;
$color-green-60: #3ad4b3;
$color-green-50: #3fe1b0;
$color-green-40: #54ffbd;
$color-green-30: #88ffd1;
$color-green-20: #b3ffe3;
$color-green-10: #d1ffee;
$color-green-05: #e3fff3;

// Neutral Colors
// Grays:
$color-black: #000000;
$color-grey-60: #0c0c0d;
$color-grey-50: #3d3d3d;
$color-grey-40: #6d6d6e;
$color-grey-30: #9e9e9e;
$color-grey-20: #cececf;
$color-grey-10: #e7e7e7;
$color-grey-05: #f9f9fa; // background colour

// Whites:
$color-white: #ffffff;
$color-white-80: rgba($color-white, 0.8);
$color-white-60: rgba($color-white, 0.6);
$color-white-40: rgba($color-white, 0.4);
$color-white-20: rgba($color-white, 0.2);

// Functional Colors
// Informational:
$color-informational: #0060df;
$color-informational-active: #054096;
$color-informational-hover: #0250bb;
$color-informational-focus: #99bff2;

// Success:
$color-success: #3fe1b0;
$color-success-active: #1cc4a0;
$color-success-hover: #3ad4b3;
$color-success-focus: #b3ffe3;

// Error
$color-error: #e22850;
$color-error-active: #810220;
$color-error-hover: #c50042;
$color-error-focus: #ffbdc5;

// Warning
$color-warning: #ffa436;
$color-warning-active: #c45a27;
$color-warning-hover: #e27f2e;
$color-warning-focus: #ffd5b2;

// Secondary Colors
// Violet
$color-violet-90: #2b1141;
$color-violet-80: #4e1a69;
$color-violet-70: #722291;
$color-violet-60: #952bb9;
$color-violet-50: #b833e1;
$color-violet-40: #d74cf0;
$color-violet-30: #f770ff;
$color-violet-20: #f68fff;
$color-violet-10: #f6b8ff;
$color-violet-05: #f7e2ff;

// Blue
$color-blue-90: #09204d;
$color-blue-80: #073072;
$color-blue-70: #054096;
$color-blue-60: #0250bb;
$color-blue-50: #0060df;
$color-blue-40: #0090ed;
$color-blue-30: #00b3f4;
$color-blue-20: #00ddff;
$color-blue-10: #80ebff;
$color-blue-05: #aaf2ff;

// Orange
$color-orange-90: #7c1504;
$color-orange-80: #9e280b;
$color-orange-70: #cc3d00;
$color-orange-60: #e25920;
$color-orange-50: #ff7139;
$color-orange-40: #ff8a50;
$color-orange-30: #ffa266;
$color-orange-20: #ffb587;
$color-orange-10: #ffd5b2;
$color-orange-05: #fff4de;

// Yellow
$color-yellow-90: #960e18;
$color-yellow-80: #a7341f;
$color-yellow-70: #c45a27;
$color-yellow-60: #e27f2e;
$color-yellow-50: #ffa436;
$color-yellow-40: #ffbd4f;
$color-yellow-30: #ffd567;
$color-yellow-20: #ffea80;
$color-yellow-10: #ffff98;
$color-yellow-05: #ffffcc;

// Red
$color-red-90: #440306;
$color-red-80: #810220;
$color-red-70: #c50042;
$color-red-60: #e22850;
$color-red-50: #ff4f5e;
$color-red-40: #ff6a75;
$color-red-30: #ff848b;
$color-red-20: #ff9aa2;
$color-red-10: #ffbdc5;
$color-red-05: #ffdfe7;

// Pink
$color-pink-90: #50134b;
$color-pink-80: #7f145b;
$color-pink-70: #c60084;
$color-pink-60: #e31587;
$color-pink-50: #ff298a;
$color-pink-40: #ff4aa2;
$color-pink-30: #ff6bba;
$color-pink-20: #ff8ac5;
$color-pink-10: #ffb4db;
$color-pink-05: #ffdef0;

// Gradient
$gradient-blue: linear-gradient(
  to right,
  $color-violet-50 0%,
  $color-purple-50 37.1%,
  $color-blue-40 61.4%,
  #0090ed 100%
);
$gradient-linear-purple: linear-gradient(
  15deg,
  $color-violet-30,
  $color-purple-60
);
$monitor-gradient: linear-gradient(
  -90deg,
  $color-yellow-50,
  $color-pink-60 50%,
  $color-purple-70
);

// Layout //
// Border Radius
$border-radius-xs: 2px;
$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 16px;
$border-radius-xl: 32px;

$border-focus-width: 3px;

// Drop shadow
$box-shadow-xs:
  0 4px 6px rgba(29, 17, 51, 0.02),
  0 2px 8px rgba(9, 32, 77, 0.08),
  0 3px 5px -2px rgba(29, 17, 51, 0.08);
$box-shadow-sm:
  0 8px 12px 1px rgba(29, 17, 51, 0.04),
  0 3px 16px 2px rgba(9, 32, 77, 0.12),
  0 5px 10px -3px rgba(29, 17, 51, 0.12);
$box-shadow-md:
  0 16px 24px 2px rgba(29, 17, 51, 0.04),
  0 6px 32px 4px rgba(9, 32, 77, 0.12),
  0 8px 12px -5px rgba(29, 17, 51, 0.12);
$box-shadow-lg:
  0 24px 38px 3px rgba(29, 17, 51, 0.04),
  0 10px 48px 8px rgba(9, 32, 77, 0.12),
  0 12px 16px -6px rgba(29, 17, 51, 0.12);

// Content width
$content-xs: 304px;
$content-sm: 432px;
$content-md: 688px;
$content-lg: 928px;
$content-xl: 1152px;
$content-max: 1440px;

// Device width
$screen-xs: 320px;
$screen-sm: 480px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1312px;

// Spacing
// Used inside elements
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;
$spacing-2xl: 48px;

// Layouts
// Used to position elements relative to each other
$layout-2xs: 16px;
$layout-xs: 24px;
$layout-sm: 32px;
$layout-md: 48px;
$layout-lg: 64px;
$layout-xl: 96px;
$layout-2xl: 192px;

// Typeface
// Title copy
// We use `svw`  (rather than just `vw`) to ensure that the fonts look good even
// when the viewport is at its smallest (e.g. when scrollbars are visible).
// In most cases they'll snap to one of the clamped values though, so it
// doesn't matter too much.
$text-title-lg:
  700 list.slash(clamp(48px, 4.4svw, 56px), clamp(48px, 4.4svw, 56px))
    var(--font-metropolis),
  sans-serif;
$text-title-md:
  700 list.slash(clamp(38px, 3.8svw, 48px), clamp(40px, 3.8svw, 48px))
    var(--font-metropolis),
  sans-serif;
$text-title-sm:
  700 list.slash(clamp(28px, 3svw, 38px), clamp(30px, 3.16svw, 40px))
    var(--font-metropolis),
  sans-serif;
$text-title-xs:
  700 list.slash(clamp(24px, 2.2svw, 28px), clamp(26px, 2.4svw, 30px))
    var(--font-metropolis),
  sans-serif;
$text-title-2xs:
  700 list.slash(clamp(20px, 1.6svw, 24px), clamp(20px, 2svw, 26px))
    var(--font-metropolis),
  sans-serif;
$text-title-3xs:
  700 list.slash(clamp(16px, 1.4svw, 18px), 20px) var(--font-metropolis),
  sans-serif;

// Body copy
$text-body-xl:
  400 list.slash(clamp(18px, 1.7svw, 21px), 1.5) var(--font-inter),
  sans-serif;
$text-body-lg:
  400 list.slash(clamp(16px, 1.5svw, 18px), 1.5) var(--font-inter),
  sans-serif;
$text-body-md:
  400 list.slash(clamp(14px, 1.3svw, 16px), 1.5) var(--font-inter),
  sans-serif;
$text-body-sm:
  400 list.slash(clamp(12px, 1.14svw, 14px), 1.5) var(--font-inter),
  sans-serif;
$text-body-xs:
  400 12px / 1.5 var(--font-inter),
  sans-serif;
$text-body-2xs:
  400 10px / 1.5 var(--font-inter),
  sans-serif;

$tab-bar-height: 100px;
$width-first-column-filter-bar: 90px;

@mixin company-name-area {
  @media screen and (min-width: $screen-lg) {
    min-width: 200px;
  }
}

// Question circle mark for explainer dialogs
@mixin question-mark-circle-btn {
  background-color: transparent;
  border-style: none;
  padding: 0;
  cursor: pointer;
  height: 25px; // height of the button
  color: $color-grey-40;

  &:hover {
    color: $color-grey-30;
  }
}

// Estimated time
@mixin estimated-time {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  align-items: center;
  font: $text-body-xs;
  font-weight: 500;
  color: $color-grey-40;
}

@mixin visually-hidden {
  // These styles are taken from
  // https://react-spectrum.adobe.com/react-aria/VisuallyHidden.html
  // They keep an element accessible to screen readers, while making them
  // invisible in the viewport.
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: 0 -1px -1px 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

// CSS uppercasing is not reliable for some locales, see
// https://mozilla-l10n.github.io/documentation/localization/dev_best_practices.html#css-issues
// However, uppercasing the source string directly isn't great for
// accessibility, as it will cause screen readers to spell it out:
// https://www.timdunklee.com/notes/use-css-text-transform-for-uppercase-letters-a11y/
// Thus, we limit the uppercasing for English for now, where uppercasing
// is reliable enough, and fallback to regular-casing for other locales:
@mixin uppercase-only-english {
  [lang="en"] &,
  [lang="en-US"] &,
  [lang="en-CA"] &,
  [lang="en-GB"] & {
    text-transform: uppercase;
  }
}

/* Apply this class to remove default focus outline styles for non-interactive elements */
@mixin no-focus-outline {
  &:focus {
    outline: none;
  }
}
